.stc-data-cmp {
    background-image: url('/assets/img/sorteo2024/banner-datos-comprador.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .row {
        height: 750px;
        // @media only screen and (max-width:575px) {
        //     height: 320px;
        // }
        
        h1 {
            font-family: $font-bold;
            font-size: 70px;
            color: #fff;
            text-transform: uppercase;
            line-height: 1;

            span {
                display: block;
                font-family: $font-bold;
                background-color: #ff5900;
                color: #fff;
                //font-size: 70px;
                width: fit-content;
                font-family: $font-black;
                margin: 0 auto ;
                padding: 0 10px;
                box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                -webkit-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
            }

            @media only screen and (max-width:991px) {
                font-size: 55px;
            }
    
            @media only screen and (max-width:575px) {
                font-size: 42px;
                margin-top: 60px;
            }
        }

        img {
            width: 380px;
            object-fit: cover;
            margin: 0 auto;
            @media only screen and (max-width:575px) {
                width: 230px;
            }
        }


        
    }
}

.data-buyer-cpm {
   background-color: #eaeaea;
   padding-top: 60px;
    #tab {
        .label-info {
            position: relative;
          
           

             input[type="radio"]:checked ~ .checkma {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: #eee;
                border-radius: 50%;
                border: solid #dd741d;
                border-width: thin;

                &::after {
                    content: "";
                    top: 5px;
                    left: 5px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #dd741d;
                    position: absolute;
                }
            }

           
        }

        .checkma {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            border-radius: 50%;
            border: solid #dd741d;
            border-width: thin;
        }

    }

    h2 {
        font-family: $font-bold;
        color: #000000;
        font-size: 48px;
    }

    p {
        font-size: 18px;
        color: #000000;
    }

    .content-minute {

        span {
            font-size: 28px;
        }

        i {
            font-size: 26px;
            color: #ff5900;
        }
    }
     

    .form-vertical  {
        padding: 60px 0;

        label {
            font-size: 18px;
            color: #ff4700;
        }

        input, select {
            border-radius: 100px !important;
            width: 100%;
        }

        input[type="radio"], input[type="checkbox"] {
            accent-color:#fa4808;
            margin-right: 10px;
            transform: scale(1.3);
            width: auto;
        }

        .is-valid {
            border-color: #28a745;
        }

        .is-invalid {
            color: #dc3545;
        }   

        .btn-stack {
            border: none;
            border: 1px solid #fa4808;
            color: #fff;
            background-color: #fa4808;
            margin: 15px 0;
            &.search {
               
            }

            &:hover {
                color: #fa4808;
                background-color: #fff;
            }

            &.purple {
                background-color: #6f5dff;
                color: #fff;
                border: 1px solid #6f5dff;
               

                &:hover {
                    color: #6f5dff;
                    background-color: #fff;
                }
            }
        }

        a {
            color: #fc5705;
        }
    }

    .form {
        border: 1px solid #fc5705;
        border-radius: 30px;
        padding: 40px;
        @media only screen and (max-width: 767px) {
            padding: 15px;
        }
    }

}

