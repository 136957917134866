// FONTS

@font-face {
    font-family: 'sofiapro black';
    src: url("/assets/fuentes/sofia-pro-black.woff") format("woff");
    font-display: swap;

}

@font-face {
    font-family: "sofiapro bold";
    src: url("/assets/fuentes/sofiapro-bold-webfont.woff") format("woff");
    font-display: swap;

}

@font-face {
    font-family: "sofiapro";
    src: url("/assets/fuentes/sofiapro-regular-webfont.woff") format("woff");
    font-display: swap;

}

@font-face {
    font-family: 'sofiapro-light';
    src: url("/assets/fuentes/sofiapro-light-webfont.woff") format("woff");
    font-display: swap;

}

@font-face {
    font-family: 'sofiapro-italic';
    src: url("/assets/fuentes/sofia-pro-italic.woff") format("woff");
    font-display: swap;

}

$font-black: "sofiapro black",
sans-serif;
$font-bold: "sofiapro bold",
sans-serif;
$font-stack: "sofiapro",
sans-serif;
$font-light: "sofiapro-light",
sans-serif;

$font-italic: "sofiapro-italic",
sans-serif;

//NEW COLORS

$color-primary: #ff5900;
$color-secondary: #fff;
$color-paragraph: #000; 
$color-button: #8560d2;


//

$mg-top: 100px;

