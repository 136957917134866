#main-header {
    background: rgb(255,255,255);
    //background: linear-gradient(0deg, rgb(255, 255, 255) 8%, rgb(255, 255, 255) 10%, rgb(255, 255, 255) 30%);
    padding: 30px 0 ;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: -10px;
    box-shadow: 0px 8px 12px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 8px 12px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 8px 12px -6px rgba(0,0,0,0.75);
      .mobile-nav-trigger {
          float: right;
          margin-left: 15px;
          margin-top: 15px;
      }
    .main-nav {
        .site-navigation {
            list-style: none;
            margin: 0;
            float: right;
            li {
                display: inline-block;
                a {
                    color: #272726;
                    font-size: 18px;
                    margin: 0 15px;
                    font-weight: 300;
                    text-decoration: none;
                    padding: 2px 0;
                    font-family: $font-bold;
                    &.active {
                        color: $color-primary;
                    }
                    
                }

                &.drop {
                    .dropdown-toggle {
                        &::after {
                            right: -15px;
                            top: 15px;
                            background: #333;
                        }

                    }

                    .dropdown-menu {
                        padding: 25px 20px !important;
                        top: 10px !important;

                        a {
                            &:hover {
                                 background-color: transparent;
                             }
                         }

                         .number-count {
                             color: #fff;
                             span {
                                 font-size: 20px;
                                 font-family: $font-stack;
                                 color: #fff;
                             }
                         }

                         .labels {
                             span {
                                 font-size: 14px;
                                 font-family: $font-stack;
                                 color: #fff;
                             }
                         }

                        .btn1 {
                            background-color: #faaf3a;
                            padding: 10px;
                            border-radius: 10px;
                        }

                        .btn2 {
                            background-color: #8560d2;
                            padding: 10px;
                            border-radius: 10px;
                            margin-top: 15px;
                        }

                        .btn3 {
                            background-color: #ff4700;
                            padding: 10px;
                            border-radius: 10px;
                            margin-top: 15px;
                        }

                        
                    }
                }
            }
        }


    }
}


.mobile-menu {
    transition: right 0.3s ease-in-out;
    top: 0;
    right: -400px;
    height: 100vh;
    position: fixed;
    width: 400px;
    overflow-x: hidden;
    background-color: rgb(0, 0, 0);
    padding: 40px;
    z-index: 10;

    @media only screen and (max-width: 575px) {
        width: 300px;
    }

    .close {
        color: #fff;
        font-family: $font-bold;
        font-size: 55px;
        position: absolute;
        top: -10px;
        left: 10px;
        cursor: pointer;
    } 
    
    &.active-nav {
        overflow-x: hidden;
        position: fixed;
        right: 0px;
        border: none;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -10px;
            border-radius: inherit;
            background: linear-gradient(to right, rgba(255,255,255,0.2), #000);
            width: 40px;
        } 
    }


    h3 {
        font-family: $font-bold;
        font-size: 30px;
        color: #fff;
    }

    hr.line {
        width: 80px;
        border-top: 5px solid $color-primary;
        margin: 5px 0 10px;
    }
    ul {
        li {
            a {
                color: $color-secondary;
                font-size: 18px;
                cursor: pointer;
                &.login {
                    border: 2px solid $color-primary;
                    padding: 0px 30px;
                    font-family: $font-bold;
                    margin-top: 10px;
                }

                &.active {
                    color: $color-primary;
                }

                @media only screen and (max-width: 575px) {
                    font-size: 14px;
                }
            }
        }
    }
}

nav {
    ul {
        &>* {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.35s ease;
            transition: all 0.35s ease;
        }
    }

    li:not(.drop) {
        list-style: outside none none;
        padding: 0;
        a {
            position: relative;
            text-decoration: none !important;
            display: inline-block;

            

            &::before,&::after {
                height: 3px;
                position: absolute;
                content: '';
                -webkit-transition: all 0.35s ease;
                transition: all 0.35s ease;
                background-color: $color-primary;
                width: 0;
            }

            &:before {
                top: 0;
                left: 0;
            }

            &:after {
                bottom: 0;
                right: 0;
            }

            &.last-child {
                background-color: #fff;
                border-radius: 100px;
                padding: 2px 20px !important;
                margin: 10px 5px !important;
                color: $color-primary !important;
                
                &::before,&::after {
                    content: none !important;
                }
            }

           
        }

        a:hover:before,
        .current a:before,
        a:hover:after,
        .current a:after {
            width: 100%;
        }
    }
}