.sct-forma-pago {
    background-image: url('/assets/img/sorteo2024/banner-forma-pago.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .row {
        height: 650px;
       
        h1 {
            font-family: $font-bold;
            font-size: 70px;
            color: #fff;
            text-transform: uppercase;
            line-height: 1.2;

            span {
                font-family: $font-bold;
                background-color: #8661d2;
                color: #fff;
                width: fit-content;
                padding: 0 10px;
                box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                -webkit-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
            }

            @media only screen and (max-width:991px) {
                font-size: 55px;
            }

    
            @media only screen and (max-width:767px) {
                font-size: 42px;
                margin-top: 100px;
            }
        }

        @media only screen and (max-width:767px) {
            img {
                width: 330px;  
            }
        }
    }
}

.section-payment {
    background-color: #eaeaea;
    padding: 60px 0;
    @media only screen and (max-width: 575px) {
        padding: 0px 15px 0 ;
    }
    .container-pago {
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
        border-radius: 10px;
        padding: 130px 40px 80px;
        @media only screen and (max-width:767px) {
            padding: 130px 40px 80px;
        }
        
        @media only screen and (max-width: 575px) {
            padding: 40px 0;
        }
        #timer {
            i {
                font-size: 26px;
                color: #ff5900;
            }
            span {
                font-size: 28px;
            }
        }

        label {
            color: #ff4700;
            font-family: $font-bold;
            font-size: 18px;
        }

        input[type="radio"], input[type="checkbox"] {
            accent-color:#fc5705;
            transform: scale(1.2);
            margin-right: 10px;
        }

        p{
            font-size: 18px;
            color: #333;
        }
        h2{
            font-size: 28px;
            font-family: $font-bold;
            text-transform: uppercase;
            color: #333;
        }
        .metodo-pago-cont {
            padding: 30px 0;
        }

        .BoletosPay {
            border: 2px solid #65479f;
            border-radius: 10px;
            padding: 30px;
            @media only screen and (max-width:575px) {
                padding: 15px;
            }
            margin-bottom: 25px;
            label  {
                font-size: 25px;
                @media only screen and (max-width:575px) {
                    font-size: 18px;
                }
            }
        }
    }
}

#ctaMorePay {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: $font-bold;
    padding: 15px ;
    text-align: right;
    padding-bottom: 25px;
    &:hover {
        color: #ff5900;
    }
}

/* styles.css o app.component.css */
.alertify {
  
    .ajs-dialog {
        padding:  40px;
        width: 80%; /* Ajusta el ancho del modal */
        max-width: 800px !important; /* Ajusta el ancho máximo del modal */
        border-radius: 10px;
        @media only screen and (max-width:575px) {
            width: 100%;
            padding: 40px 25px;
        }

        .ajs-header {
            font-family: $font-bold;
            font-size: 24px;
        }

        ul {
            li {
                font-size: 16px;
                margin-top: 15px;
                @media only screen and (max-width:575px) {
                    font-size: 14px;
                }
            }
        }

        .ajs-cancel {
            border: none;
            background-color: transparent;
            color: #ff5900;
            transition: border-bottom 0.2s ease-in-out;
            &:hover {
                border-bottom: 1px solid #ff5900;
            }
        }

        .ajs-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .ajs-ok {
            display: inline-block;
            padding: 5px 40px; 
            background-color: #ff5900;
            color: #fff;
            font-size: 18px;
            font-family: $font-bold;
            border-radius: 100px;
            cursor: pointer;
            display: inline-block;
            text-decoration: none;
            text-transform: uppercase;
            overflow: hidden;
            position: relative;
            z-index: 1;
            transition: all 0.2s ease-in-out;
            border: 1px solid #ff5900;

            @media only screen and (max-width:575px) {
                font-size: 14px;
            }
            &:hover:not(.generate-number) {
                color: #ff5900;
                background-color: #fff;
                //border: 1px solid #ff5900 !important;
            }
        }

    }

    @media only screen and (max-width:575px) {
        .ajs-primary{
            text-align: center !important;
        }
    }
    
}
  


