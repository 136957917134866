.pay-msg {
    background-image: url('/assets/img/sorteo2023/banner-forma-pago.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .row {
        height: 100vh;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
        padding: 80px;
        h2 {
            font-family: $font-bold;
            font-size: 60px;
        }

        @media only screen and (max-width: 767px) {
            padding: 60px 15px;
            h2 {
                font-family: $font-bold;
                font-size: 42px;
                color: #8560d2;
                line-height: 1;
            }

        }
    }

}