@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'sofiapro black';
  src: url("/assets/fuentes/sofia-pro-black.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "sofiapro bold";
  src: url("/assets/fuentes/sofiapro-bold-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "sofiapro";
  src: url("/assets/fuentes/sofiapro-regular-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'sofiapro-light';
  src: url("/assets/fuentes/sofiapro-light-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'sofiapro-italic';
  src: url("/assets/fuentes/sofia-pro-italic.woff") format("woff");
  font-display: swap;
}

/* GENERAL STYLES */
* {
  margin: 0;
  padding: 0;
  font-family: "sofiapro", sans-serif;
}

:root {
  --my-start-px0: -760px;
  --my-start-px1: -760px;
  --my-start-px2: -760px;
  --my-start-px3: -760px;
  --my-start-px4: -760px;
  --my-start-px5: -760px;
}

.hamburger {
  padding: 0 !important;
  margin: 0 !important;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: #272726;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #272726;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px  !important;
  height: 1px  !important;
}

/**/
body {
  position: relative;
  overflow-x: hidden;
}

.overlay {
  top: -100vh;
}

.overlay.active-overlay {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  width: calc(100% - 400px);
  top: 0;
  transition: top 0.5s ease-in-out;
  z-index: 10;
}

@media only screen and (max-width: 575px) {
  .overlay.active-overlay {
    width: calc(100% - 300px);
  }
}

.slick-slide {
  height: auto;
}

.btn-stack {
  display: inline-block;
  padding: 10px 30px;
  background-color: #ff5900;
  color: #fff;
  font-size: 18px;
  font-family: "sofiapro bold", sans-serif;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.btn-stack:hover:not(.generate-number) {
  color: #ff5900;
  background-color: #fff;
}

.btn-search {
  background-color: #6f5dff;
}

.btn-search:hover:not(.generate-number) {
  color: #6f5dff;
  background-color: #fff;
  border: 1px solid #6f5dff;
}

/*******loader******/
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 1px 19px 13px;
  box-sizing: border-box;
  border: 22px solid #ff5900;
  border-color: #ff5900 transparent #ff5900 transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

/***********/
/* BORDER COLOR**/
.border-gradient-all {
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #fc5705, #911acf) border-box;
  border-radius: 100px;
  border: 4px solid transparent;
}

.font-bold {
  font-family: "sofiapro bold", sans-serif;
}

a:focus,
button:focus,
a,
button {
  outline: none !important;
  text-decoration: none !important;
}

.filters ul {
  list-style: none;
  padding: 0;
}

.filters ul li {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.filters ul li + li {
  margin-left: 20px;
}

.filters ul li:hover, .filters ul li.active {
  color: #000;
  border-bottom: 1px solid black;
}

/********LATIDO EGRESADO********/
.latido-egr {
  animation: latido 5s infinite;
}

@keyframes latido {
  from {
    transform: none;
  }
  50% {
    transform: scale(1.09);
  }
  to {
    transform: none;
  }
}

h1,
h2,
h5,
p,
a,
label,
select,
ul,
li,
ol,
th,
tr,
th {
  font-family: "sofiapro", sans-serif;
}

h1, h2, h3 {
  color: #fff;
}

/**SLIDE*/
@media only screen and (max-width: 767px) {
  .slick-dots {
    bottom: 25px !important;
  }
  .slick-dots li button:before {
    color: #fff !important;
    font-size: 10PX;
  }
}

/*MODALS*/
#modalenrollment .modal-content {
  padding: 20px 25px;
  border-radius: 30px;
  border: 10px solid transparent;
}

#modalenrollment .modal-content label {
  font-size: 20px;
  color: #818181;
  text-transform: uppercase;
  font-family: "sofiapro bold", sans-serif;
}

#modalenrollment .modal-content label.text {
  font-size: 14px;
  color: #000000;
  font-family: "sofiapro", sans-serif;
  text-transform: none;
  padding: 10px 25px;
}

#modalenrollment .modal-content .modal-footer {
  border: none;
  margin: 0 auto;
}

#modalenrollment .modal-content input, #modalenrollment .modal-content select {
  border: 1px solid #7d7d7d;
  border-radius: 15px;
}

#modalenrollment .modal-content .form-control:focus {
  border-color: #fc5705;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(252, 87, 5, 0.6);
}

#modalenrollment .modal-content .btn-modal {
  border: 1px solid #fc5705;
  border-radius: 10px;
  padding: 5px 20px;
  display: inline-block;
  color: #000000;
  font-family: "sofiapro", sans-serif;
  font-size: 18px;
  background-color: #fff;
  width: 120px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

#modalenrollment .modal-content .btn-modal:hover {
  background-color: #fc5705;
  color: #fff;
}

#modalenrollment .alert-danger {
  color: #fff;
  background-color: #eb4d10;
  border-color: #eb4d10;
  border-radius: 100px;
}

@media only screen and (max-width: 400px) {
  #modalenrollment .modal-content {
    padding: 25px 0  !important;
  }
  #modalenrollment .modal-content p {
    font-size: 16px;
  }
}

/*pulse maquina*/
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

/*PULSE ARROW*/
@-webkit-keyframes pulseArrow {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes pulseArrow {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.spinnback {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  border: whitesmoke;
  border-style: solid;
  color: whitesmoke;
}

.spinnback .spinn {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100;
}

.spinnback .spinn label {
  font-size: 32px;
  font-family: "sofiapro bold", sans-serif;
}

.opc-0 {
  opacity: 0;
}

/*PULSE ARROW*/
@-webkit-keyframes pulseArrow {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes pulseArrow {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

#main-header {
  background: white;
  padding: 30px 0;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: -10px;
  box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.75);
}

#main-header .mobile-nav-trigger {
  float: right;
  margin-left: 15px;
  margin-top: 15px;
}

#main-header .main-nav .site-navigation {
  list-style: none;
  margin: 0;
  float: right;
}

#main-header .main-nav .site-navigation li {
  display: inline-block;
}

#main-header .main-nav .site-navigation li a {
  color: #272726;
  font-size: 18px;
  margin: 0 15px;
  font-weight: 300;
  text-decoration: none;
  padding: 2px 0;
  font-family: "sofiapro bold", sans-serif;
}

#main-header .main-nav .site-navigation li a.active {
  color: #ff5900;
}

#main-header .main-nav .site-navigation li.drop .dropdown-toggle::after {
  right: -15px;
  top: 15px;
  background: #333;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu {
  padding: 25px 20px !important;
  top: 10px !important;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu a:hover {
  background-color: transparent;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu .number-count {
  color: #fff;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu .number-count span {
  font-size: 20px;
  font-family: "sofiapro", sans-serif;
  color: #fff;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu .labels span {
  font-size: 14px;
  font-family: "sofiapro", sans-serif;
  color: #fff;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu .btn1 {
  background-color: #faaf3a;
  padding: 10px;
  border-radius: 10px;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu .btn2 {
  background-color: #8560d2;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}

#main-header .main-nav .site-navigation li.drop .dropdown-menu .btn3 {
  background-color: #ff4700;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}

.mobile-menu {
  transition: right 0.3s ease-in-out;
  top: 0;
  right: -400px;
  height: 100vh;
  position: fixed;
  width: 400px;
  overflow-x: hidden;
  background-color: black;
  padding: 40px;
  z-index: 10;
}

@media only screen and (max-width: 575px) {
  .mobile-menu {
    width: 300px;
  }
}

.mobile-menu .close {
  color: #fff;
  font-family: "sofiapro bold", sans-serif;
  font-size: 55px;
  position: absolute;
  top: -10px;
  left: 10px;
  cursor: pointer;
}

.mobile-menu.active-nav {
  overflow-x: hidden;
  position: fixed;
  right: 0px;
  border: none;
}

.mobile-menu.active-nav:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -10px;
  border-radius: inherit;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #000);
  width: 40px;
}

.mobile-menu h3 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 30px;
  color: #fff;
}

.mobile-menu hr.line {
  width: 80px;
  border-top: 5px solid #ff5900;
  margin: 5px 0 10px;
}

.mobile-menu ul li a {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.mobile-menu ul li a.login {
  border: 2px solid #ff5900;
  padding: 0px 30px;
  font-family: "sofiapro bold", sans-serif;
  margin-top: 10px;
}

.mobile-menu ul li a.active {
  color: #ff5900;
}

@media only screen and (max-width: 575px) {
  .mobile-menu ul li a {
    font-size: 14px;
  }
}

nav ul > * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

nav li:not(.drop) {
  list-style: outside none none;
  padding: 0;
}

nav li:not(.drop) a {
  position: relative;
  text-decoration: none !important;
  display: inline-block;
}

nav li:not(.drop) a::before, nav li:not(.drop) a::after {
  height: 3px;
  position: absolute;
  content: '';
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  background-color: #ff5900;
  width: 0;
}

nav li:not(.drop) a:before {
  top: 0;
  left: 0;
}

nav li:not(.drop) a:after {
  bottom: 0;
  right: 0;
}

nav li:not(.drop) a.last-child {
  background-color: #fff;
  border-radius: 100px;
  padding: 2px 20px !important;
  margin: 10px 5px !important;
  color: #ff5900 !important;
}

nav li:not(.drop) a.last-child::before, nav li:not(.drop) a.last-child::after {
  content: none !important;
}

nav li:not(.drop) a:hover:before,
nav li:not(.drop) .current a:before,
nav li:not(.drop) a:hover:after,
nav li:not(.drop) .current a:after {
  width: 100%;
}

/**********BTN WHATS*********/
.btn-call {
  position: fixed;
  right: -130px;
  top: 45%;
  transition: right 0.3s ease-in-out;
  z-index: 9;
}

.btn-call:hover {
  right: -15px;
}

.btn-call .row {
  background-color: #fff;
  border: 1px solid #959595;
  border-radius: 100px;
  padding: 0;
}

.btn-call .row img {
  padding-right: 10px;
  width: 115px;
}

.btn-call .row span {
  font-family: "sofiapro bold", sans-serif;
  font-size: 12px;
  color: #333333;
  line-height: 1;
  text-transform: uppercase;
}

#main-footer {
  padding: 40px 90px 20px;
}

@media only screen and (max-width: 575px) {
  #main-footer {
    padding: 40px 0px 20px;
  }
}

#main-footer .logo-footer {
  margin-bottom: 30px;
}

#main-footer a {
  margin: 10px 0;
  font-size: 16px;
  font-family: "sofiapro", sans-serif;
  color: #333333;
  transition: color 0.3s ease-in-out;
}

#main-footer a:hover {
  color: #ff5900;
}

#main-footer h4 {
  font-size: 22px;
  font-family: "sofiapro bold", sans-serif;
  color: #333;
  text-transform: uppercase;
}

#main-footer h5 {
  font-size: 28px;
  font-family: "sofiapro bold", sans-serif;
  color: #333;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  #main-footer ul {
    margin: 0 25px;
  }
}

#main-footer .redes img {
  width: 32px;
  object-fit: cover;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

#main-footer .redes img:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 1200px) {
  #main-footer .redes img {
    width: 25px;
  }
}

@media only screen and (max-width: 991px) {
  #main-footer .redes {
    margin-top: 25px !important;
  }
  #main-footer .redes img {
    width: 35px;
  }
}

#main-footer .sto {
  margin: 30px 0;
}

#main-footer .sto .main-sorteo {
  border-radius: 10px;
  padding: 25px 30px;
  margin: 15px 50px;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
}

#main-footer .sto .main-sorteo p {
  font-size: 14px;
  font-family: "sofiapro bold", sans-serif;
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (max-width: 1200px) {
  #main-footer .sto .main-sorteo {
    margin: 15px 0;
  }
}

#main-footer .legals p {
  font-family: "sofiapro", sans-serif;
  font-size: 16px;
  color: #333333;
}

@media only screen and (max-width: 991px) {
  #main-footer .legals {
    margin: 25px 0 !important;
  }
}

.derechos {
  padding: 15px;
  background-color: #e8e8e8;
}

.derechos p {
  font-family: "sofiapro", sans-serif;
  font-size: 14px;
  color: #333333;
}

.campus {
  background-color: #8560d2;
  padding: 50px 0 80px;
  max-height: 290px;
}

@media only screen and (max-width: 1300px) {
  .campus {
    max-height: inherit;
  }
}

.campus h5 {
  text-transform: uppercase;
  font-size: 42px;
  color: #fff;
  font-family: "sofiapro black", sans-serif;
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  .campus h5 {
    font-size: 32px;
  }
}

.campus img {
  margin-bottom: 30px;
}

.banner-home {
  background-image: url("/assets/img/sorteo2024/banner-agrada.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 100px;
}

.banner-home .row {
  height: 600px;
}

@media only screen and (max-width: 991px) {
  .banner-home .row {
    height: 850px;
    padding: 60px 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-home .row {
    height: 700px;
    padding: 30px 0;
  }
}

.banner-home .row .zod h1 {
  font-size: 40px !important;
}

.banner-home .row .zod h1 span {
  font-size: 40px !important;
}

@media only screen and (max-width: 991px) {
  .banner-home .row .zod h1 span {
    font-size: 32px !important;
  }
}

@media only screen and (max-width: 991px) {
  .banner-home .row .zod h1 {
    font-size: 32px !important;
  }
}

.banner-home .row .zod h2 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 120px;
  margin-top: -25px;
  text-transform: uppercase;
  letter-spacing: -4px;
}

.banner-home .row .zod h2 span {
  font-family: "sofiapro bold", sans-serif;
  font-size: 42px;
}

@media only screen and (max-width: 991px) {
  .banner-home .row .zod h2 span {
    font-size: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-home .row .zod h2 {
    margin-top: -10px;
    font-size: 60px;
    letter-spacing: 0px;
  }
}

.banner-home .row .zod .btn-stack {
  background-color: #8661d2;
  font-size: 22px !important;
  border: 1px solid #8661d2;
}

.banner-home .row .zod .btn-stack:hover {
  background-color: #ff5900;
  color: #fff;
  border: 1px solid #fff;
}

@media only screen and (max-width: 991px) {
  .banner-home .row .zod .btn-stack {
    font-size: 16px !important;
  }
}

.banner-home .row .content-text {
  margin-left: -80px;
}

@media only screen and (max-width: 991px) {
  .banner-home .row .content-text {
    margin-left: 0px;
  }
}

.banner-home .row .content-text h1 .head-h1 {
  font-family: "sofiapro black", sans-serif;
  font-size: 46px;
  margin-top: 30px;
  text-transform: uppercase;
  background-color: #ff9d00;
  width: fit-content;
}

.banner-home .row .content-text h1 span {
  background-color: #662380;
  color: #fff;
  font-size: 38px;
  font-family: "sofiapro black", sans-serif;
  margin: 0 auto;
  padding: 0 10px;
  box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 575px) {
  .banner-home .row .content-text h1 .head-h1 {
    font-size: 28px;
  }
  .banner-home .row .content-text h1 span {
    font-size: 22px;
  }
}

.banner-home .row p {
  font-family: "sofiapro", sans-serif;
  font-size: 20px;
  color: #fff;
}

.banner-home .row p span {
  font-family: "sofiapro black", sans-serif;
}

@media only screen and (max-width: 737px) {
  .banner-home .row img {
    margin-top: -50px;
  }
}

#lucky {
  background-image: url(/assets/img/sorteo2024/bg-machine.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 100px 0;
}

#lucky h3 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 45px;
  text-transform: uppercase;
  line-height: 1;
}

#lucky h3 span {
  font-family: "sofiapro bold", sans-serif;
}

@media only screen and (max-width: 575px) {
  #lucky h3 {
    font-size: 32px;
  }
}

#lucky p {
  font-family: "sofiapro black", sans-serif;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 25px;
}

@media only screen and (max-width: 575px) {
  #lucky p {
    font-size: 16px;
  }
}

#lucky .content-machine {
  -webkit-box-shadow: 0px 4px 129px 22px #fce450;
  -moz-box-shadow: 0px 4px 129px 22px #fce450;
  box-shadow: 0px 4px 129px 22px #fce450;
  border: 4px solid #fdfcf1;
  border-radius: 30px;
  padding: 25px 35px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  #lucky .content-machine {
    padding: 25px 10px;
  }
}

#lucky .content-machine .numbers {
  list-style: none;
  flex-direction: column;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
}

@media only screen and (max-width: 380px) {
  #lucky .content-machine .numbers {
    margin-right: 6px;
  }
}

@media only screen and (max-width: 330px) {
  #lucky .content-machine .numbers {
    margin-right: 2px;
  }
}

#lucky .content-machine .numbers li {
  color: #fff;
  font-family: "sofiapro";
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
  margin-left: 12px;
  height: 40px;
}

@media only screen and (max-width: 1200px) {
  #lucky .content-machine .numbers li {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 991px) {
  #lucky .content-machine .numbers li {
    margin-left: 11px;
  }
}

@media only screen and (max-width: 460px) {
  #lucky .content-machine .numbers li {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 435px) {
  #lucky .content-machine .numbers li {
    margin-left: 7px;
  }
}

#lucky .buttons-generate a {
  display: block;
  width: fit-content;
  font-size: 16px;
  margin-top: 25px;
}

@media only screen and (max-width: 390px) {
  #lucky .buttons-generate a {
    min-width: 290px;
  }
}

#lucky .buttons-generate a.generate-number {
  background-color: #e8531e;
  overflow: visible;
  color: #fff;
  padding: 20px 40px;
  line-height: 1;
  animation: pulse 2s infinite;
}

@media only screen and (max-width: 359px) {
  #lucky .buttons-generate a.generate-number {
    font-size: 14px;
  }
}

.animation-list0 {
  animation: girar0 6s;
  animation-fill-mode: forwards;
}

.animation-list1 {
  animation: girar1 6s;
  animation-fill-mode: forwards;
}

.animation-list2 {
  animation: girar2 6s;
  animation-fill-mode: forwards;
}

.animation-list3 {
  animation: girar3 6s;
  animation-fill-mode: forwards;
}

.animation-list4 {
  animation: girar4 6s;
  animation-fill-mode: forwards;
}

.animation-list5 {
  animation: girar5 6s;
  animation-fill-mode: forwards;
}

@keyframes girar0 {
  100% {
    transform: translateY(var(--my-start-px0));
  }
}

@keyframes girar1 {
  100% {
    transform: translateY(var(--my-start-px1));
  }
}

@keyframes girar2 {
  100% {
    transform: translateY(var(--my-start-px2));
  }
}

@keyframes girar3 {
  100% {
    transform: translateY(var(--my-start-px3));
  }
}

@keyframes girar4 {
  100% {
    transform: translateY(var(--my-start-px4));
  }
}

@keyframes girar5 {
  100% {
    transform: translateY(var(--my-start-px5));
  }
}

.btn-gradient {
  -webkit-box-shadow: 0px 4px 129px 22px #fce450;
  -moz-box-shadow: 0px 4px 129px 22px #fce450;
  box-shadow: 0px 4px 129px 22px #fce450;
  border: 4px solid #fdfcf1;
  border-radius: 100%;
  height: 55px;
  width: 55px;
}

#stc-inputs {
  background-color: #eaeaea;
}

#stc-inputs h3 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 40px;
  color: #000;
}

#stc-inputs h3 span {
  font-family: "sofiapro bold", sans-serif;
}

@media only screen and (max-width: 575px) {
  #stc-inputs h3 {
    font-size: 32px;
  }
}

#stc-inputs .container-input {
  padding: 80px 0;
}

#stc-inputs .container-input .inside {
  padding: 40px 25px;
}

#stc-inputs .container-input .inside label {
  position: relative;
  width: 100%;
  font-size: 21px;
  color: #333333;
  line-height: 1;
}

#stc-inputs .container-input .inside label input[type="radio"], #stc-inputs .container-input .inside label input[type="checkbox"] {
  accent-color: #6c439b;
  position: absolute;
  left: -25px;
  top: 8px;
  width: auto;
  -ms-transform: scale(1.3);
  /* IE 9 */
  -webkit-transform: scale(1.3);
  /* Chrome, Safari, Opera */
  transform: scale(1.3);
}

@media only screen and (max-width: 575px) {
  #stc-inputs .container-input .inside label {
    font-size: 18px;
  }
}

#stc-inputs .container-input .inside input {
  border: 1px solid #ff5900;
  width: 100%;
  border-radius: 100px;
  text-align: center;
}

#stc-inputs .container-input .inside::placeholder {
  color: #ff5900;
  opacity: 1;
  /* Firefox */
}

#stc-inputs .container-input .inside::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #ff5900;
}

#stc-inputs .container-input .btn-stack {
  border: 0;
  margin-top: 25px;
  font-size: 16px;
}

.ocultar {
  display: none;
}

#modalBoletos .row h4, #modalBoletos .row h5, #modalBoletos2 .row h4, #modalBoletos2 .row h5 {
  font-size: 30px;
  font-family: "sofiapro bold", sans-serif;
}

@media only screen and (max-width: 575px) {
  #modalBoletos .row h4, #modalBoletos .row h5, #modalBoletos2 .row h4, #modalBoletos2 .row h5 {
    font-size: 18px;
  }
}

#modalBoletos .row input[type="radio"], #modalBoletos .row input[type="checkbox"], #modalBoletos2 .row input[type="radio"], #modalBoletos2 .row input[type="checkbox"] {
  accent-color: #fc5705;
  position: absolute;
  left: 25px;
  top: 5px;
  width: auto;
}

@media only screen and (max-width: 400px) {
  #modalBoletos .row input[type="radio"], #modalBoletos .row input[type="checkbox"], #modalBoletos2 .row input[type="radio"], #modalBoletos2 .row input[type="checkbox"] {
    left: 10px;
  }
}

#modalBoletos .modal-footer .btn-stack, #modalBoletos2 .modal-footer .btn-stack {
  border: none;
  font-size: 16px;
}

@media only screen and (max-width: 400px) {
  #modalBoletos .modal-footer, #modalBoletos2 .modal-footer {
    justify-content: center;
  }
}

#modalBoletos2 h5 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 22px;
}

#modalBoletos2 .btn-stack {
  border: 1px solid #ff5900 !important;
}

#popupamigable {
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: all .5s;
  z-index: 8;
  overflow-y: scroll;
}

#popupamigable .container {
  padding: 40px;
  position: absolute;
  top: 20%;
  background-color: #fff;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 1.5em;
  text-align: center;
}

#popupamigable .container label {
  color: #333;
}

#popupamigable .container input[type="radio"], #popupamigable .container input[type="checkbox"] {
  accent-color: #fc5705;
}

#popupamigable .container input {
  border-radius: 100px;
}

.shwModalCompania {
  display: block;
  background: rgba(0, 0, 0, 0.9);
  overflow: hidden;
}

.stc-carrito {
  background-image: url("/assets/img/sorteo2024/banner-carrito.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.stc-carrito .row {
  height: 750px;
}

.stc-carrito .row h1 {
  margin-left: -130px;
  font-family: "sofiapro bold", sans-serif;
  font-size: 70px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (max-width: 991px) {
  .stc-carrito .row h1 {
    margin-left: 0px;
    text-align: center;
  }
}

.stc-carrito .row h1 span {
  font-family: "sofiapro bold", sans-serif;
  background-color: #662380;
  color: #fff;
  width: fit-content;
  font-family: "sofiapro black", sans-serif;
  padding: 0 10px;
  box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 991px) {
  .stc-carrito .row h1 span {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .stc-carrito .row h1 {
    margin-top: 130px;
    font-size: 55px;
  }
}

@media only screen and (max-width: 575px) {
  .stc-carrito .row h1 {
    font-size: 38px;
  }
}

.stc-boleto {
  background-color: #eaeaea;
}

.stc-boleto .container-info {
  padding: 70px 40px 80px;
}

@media only screen and (max-width: 575px) {
  .stc-boleto .container-info {
    padding: 104px 0px 80px;
  }
}

.stc-boleto .container-info p {
  width: 100%;
  border: 2px solid transparent;
  font-size: 20px;
  color: #fff;
  padding: 15px 40px;
  background-color: #662380 !important;
}

.stc-boleto .container-info p span {
  color: #fff;
  font-family: "sofiapro black", sans-serif;
}

.stc-boleto .container-info label {
  font-size: 25px;
}

.stc-boleto .container-info label.txt {
  color: #ff5900;
  font-family: "sofiapro bold", sans-serif;
}

@media only screen and (max-width: 575px) {
  .stc-boleto .container-info label {
    font-size: 18px;
  }
}

.stc-boleto .container-info .content-minute span {
  font-size: 25px;
}

@media only screen and (max-width: 575px) {
  .stc-boleto .container-info .content-minute {
    margin-bottom: 30px;
  }
  .stc-boleto .container-info .content-minute span {
    font-size: 18px;
  }
}

.stc-boleto .container-info i {
  font-size: 25px;
  color: #ff5900;
}

@media only screen and (max-width: 575px) {
  .stc-boleto .container-info i {
    font-size: 16px;
  }
}

.stc-boleto .container-info .btn-stack {
  box-shadow: none;
  margin-top: 15px;
  font-size: 20px;
  font-family: "sofiapro", sans-serif;
  border: none;
  color: #fff;
  background-color: #ff5900;
}

.stc-boleto .container-info .btn-stack:hover {
  color: #ff5900;
  background-color: #fff;
  border: 1px solid #ff5900;
}

.stc-boleto .container-info .btn-stack.purple {
  background-color: #662380;
  color: #fff;
  border: 1px solid #662380;
}

.stc-boleto .container-info .btn-stack.purple:hover {
  color: #662380;
  background-color: #fff;
  border: 1px solid #662380;
}

@media only screen and (max-width: 575px) {
  .stc-boleto .container-info .btn-stack {
    font-size: 16px;
  }
}

.stc-data-cmp {
  background-image: url("/assets/img/sorteo2024/banner-datos-comprador.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.stc-data-cmp .row {
  height: 750px;
}

.stc-data-cmp .row h1 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 70px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}

.stc-data-cmp .row h1 span {
  display: block;
  font-family: "sofiapro bold", sans-serif;
  background-color: #ff5900;
  color: #fff;
  width: fit-content;
  font-family: "sofiapro black", sans-serif;
  margin: 0 auto;
  padding: 0 10px;
  box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 991px) {
  .stc-data-cmp .row h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 575px) {
  .stc-data-cmp .row h1 {
    font-size: 42px;
    margin-top: 60px;
  }
}

.stc-data-cmp .row img {
  width: 380px;
  object-fit: cover;
  margin: 0 auto;
}

@media only screen and (max-width: 575px) {
  .stc-data-cmp .row img {
    width: 230px;
  }
}

.data-buyer-cpm {
  background-color: #eaeaea;
  padding-top: 60px;
}

.data-buyer-cpm #tab .label-info {
  position: relative;
}

.data-buyer-cpm #tab .label-info input[type="radio"]:checked ~ .checkma {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  border: solid #dd741d;
  border-width: thin;
}

.data-buyer-cpm #tab .label-info input[type="radio"]:checked ~ .checkma::after {
  content: "";
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #dd741d;
  position: absolute;
}

.data-buyer-cpm #tab .checkma {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  border: solid #dd741d;
  border-width: thin;
}

.data-buyer-cpm h2 {
  font-family: "sofiapro bold", sans-serif;
  color: #000000;
  font-size: 48px;
}

.data-buyer-cpm p {
  font-size: 18px;
  color: #000000;
}

.data-buyer-cpm .content-minute span {
  font-size: 28px;
}

.data-buyer-cpm .content-minute i {
  font-size: 26px;
  color: #ff5900;
}

.data-buyer-cpm .form-vertical {
  padding: 60px 0;
}

.data-buyer-cpm .form-vertical label {
  font-size: 18px;
  color: #ff4700;
}

.data-buyer-cpm .form-vertical input, .data-buyer-cpm .form-vertical select {
  border-radius: 100px !important;
  width: 100%;
}

.data-buyer-cpm .form-vertical input[type="radio"], .data-buyer-cpm .form-vertical input[type="checkbox"] {
  accent-color: #fa4808;
  margin-right: 10px;
  transform: scale(1.3);
  width: auto;
}

.data-buyer-cpm .form-vertical .is-valid {
  border-color: #28a745;
}

.data-buyer-cpm .form-vertical .is-invalid {
  color: #dc3545;
}

.data-buyer-cpm .form-vertical .btn-stack {
  border: none;
  border: 1px solid #fa4808;
  color: #fff;
  background-color: #fa4808;
  margin: 15px 0;
}

.data-buyer-cpm .form-vertical .btn-stack:hover {
  color: #fa4808;
  background-color: #fff;
}

.data-buyer-cpm .form-vertical .btn-stack.purple {
  background-color: #6f5dff;
  color: #fff;
  border: 1px solid #6f5dff;
}

.data-buyer-cpm .form-vertical .btn-stack.purple:hover {
  color: #6f5dff;
  background-color: #fff;
}

.data-buyer-cpm .form-vertical a {
  color: #fc5705;
}

.data-buyer-cpm .form {
  border: 1px solid #fc5705;
  border-radius: 30px;
  padding: 40px;
}

@media only screen and (max-width: 767px) {
  .data-buyer-cpm .form {
    padding: 15px;
  }
}

.sct-forma-pago {
  background-image: url("/assets/img/sorteo2024/banner-forma-pago.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.sct-forma-pago .row {
  height: 650px;
}

.sct-forma-pago .row h1 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 70px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
}

.sct-forma-pago .row h1 span {
  font-family: "sofiapro bold", sans-serif;
  background-color: #8661d2;
  color: #fff;
  width: fit-content;
  padding: 0 10px;
  box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px -2px 29px 0px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 991px) {
  .sct-forma-pago .row h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .sct-forma-pago .row h1 {
    font-size: 42px;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .sct-forma-pago .row img {
    width: 330px;
  }
}

.section-payment {
  background-color: #eaeaea;
  padding: 60px 0;
}

@media only screen and (max-width: 575px) {
  .section-payment {
    padding: 0px 15px 0;
  }
}

.section-payment .container-pago {
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  padding: 130px 40px 80px;
}

@media only screen and (max-width: 767px) {
  .section-payment .container-pago {
    padding: 130px 40px 80px;
  }
}

@media only screen and (max-width: 575px) {
  .section-payment .container-pago {
    padding: 40px 0;
  }
}

.section-payment .container-pago #timer i {
  font-size: 26px;
  color: #ff5900;
}

.section-payment .container-pago #timer span {
  font-size: 28px;
}

.section-payment .container-pago label {
  color: #ff4700;
  font-family: "sofiapro bold", sans-serif;
  font-size: 18px;
}

.section-payment .container-pago input[type="radio"], .section-payment .container-pago input[type="checkbox"] {
  accent-color: #fc5705;
  transform: scale(1.2);
  margin-right: 10px;
}

.section-payment .container-pago p {
  font-size: 18px;
  color: #333;
}

.section-payment .container-pago h2 {
  font-size: 28px;
  font-family: "sofiapro bold", sans-serif;
  text-transform: uppercase;
  color: #333;
}

.section-payment .container-pago .metodo-pago-cont {
  padding: 30px 0;
}

.section-payment .container-pago .BoletosPay {
  border: 2px solid #65479f;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 575px) {
  .section-payment .container-pago .BoletosPay {
    padding: 15px;
  }
}

.section-payment .container-pago .BoletosPay label {
  font-size: 25px;
}

@media only screen and (max-width: 575px) {
  .section-payment .container-pago .BoletosPay label {
    font-size: 18px;
  }
}

#ctaMorePay {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: "sofiapro bold", sans-serif;
  padding: 15px;
  text-align: right;
  padding-bottom: 25px;
}

#ctaMorePay:hover {
  color: #ff5900;
}

/* styles.css o app.component.css */
.alertify .ajs-dialog {
  padding: 40px;
  width: 80%;
  /* Ajusta el ancho del modal */
  max-width: 800px !important;
  /* Ajusta el ancho máximo del modal */
  border-radius: 10px;
}

@media only screen and (max-width: 575px) {
  .alertify .ajs-dialog {
    width: 100%;
    padding: 40px 25px;
  }
}

.alertify .ajs-dialog .ajs-header {
  font-family: "sofiapro bold", sans-serif;
  font-size: 24px;
}

.alertify .ajs-dialog ul li {
  font-size: 16px;
  margin-top: 15px;
}

@media only screen and (max-width: 575px) {
  .alertify .ajs-dialog ul li {
    font-size: 14px;
  }
}

.alertify .ajs-dialog .ajs-cancel {
  border: none;
  background-color: transparent;
  color: #ff5900;
  transition: border-bottom 0.2s ease-in-out;
}

.alertify .ajs-dialog .ajs-cancel:hover {
  border-bottom: 1px solid #ff5900;
}

.alertify .ajs-dialog .ajs-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alertify .ajs-dialog .ajs-ok {
  display: inline-block;
  padding: 5px 40px;
  background-color: #ff5900;
  color: #fff;
  font-size: 18px;
  font-family: "sofiapro bold", sans-serif;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  border: 1px solid #ff5900;
}

@media only screen and (max-width: 575px) {
  .alertify .ajs-dialog .ajs-ok {
    font-size: 14px;
  }
}

.alertify .ajs-dialog .ajs-ok:hover:not(.generate-number) {
  color: #ff5900;
  background-color: #fff;
}

@media only screen and (max-width: 575px) {
  .alertify .ajs-primary {
    text-align: center !important;
  }
}

.stc-venta-exitosa {
  background-image: url("/assets/img/sorteo2024/banner-venta-exitosa.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.stc-venta-exitosa.err-p .error-pago {
  width: 400px;
  height: 300px;
  object-fit: contain;
  text-align: left;
  margin-top: 50px;
}

@media only screen and (max-width: 575px) {
  .stc-venta-exitosa.err-p .error-pago {
    width: 290px;
  }
}

.stc-venta-exitosa .row {
  height: 500px;
}

@media only screen and (max-width: 767px) {
  .stc-venta-exitosa .row {
    height: 650px;
  }
  .stc-venta-exitosa .row img {
    width: 350px;
    margin: auto;
  }
}

.stc-venta-exitosa .row h1 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 70px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}

.stc-venta-exitosa .row h1 span {
  font-family: "sofiapro bold", sans-serif;
}

@media only screen and (max-width: 991px) {
  .stc-venta-exitosa .row h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .stc-venta-exitosa .row h1 {
    font-size: 42px;
    margin-top: 100px;
  }
}

.stc-venta-exitosa .cta-bottom {
  position: absolute;
  bottom: 60px;
  left: 50%;
}

.stc-venta-exitosa .cta-bottom img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  animation: pulseArrow 2s infinite;
  cursor: pointer;
}

.venta-ext {
  background-color: #eaeaea;
}

.venta-ext .bg {
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  padding: 80px;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .venta-ext .bg {
    padding: 80px 20px;
  }
}

.venta-ext .bg input[type="radio"], .venta-ext .bg input[type="checkbox"] {
  accent-color: #fc5705;
  transform: scale(1.2);
  margin-right: 10px;
  border-radius: 10px;
}

.venta-ext .bg label {
  font-size: 20px;
  color: #000000;
  font-family: "sofiapro", sans-serif;
}

.venta-ext .bg label.title {
  font-family: "sofiapro bold", sans-serif;
}

.venta-ext .bg label.small {
  font-size: 16px;
}

.venta-ext .bg h2 {
  color: #ff4700;
  font-family: "sofiapro bold", sans-serif;
  font-size: 20px;
}

.venta-ext .bg table {
  width: 100%;
  font-family: "sofiapro", sans-serif;
}

.venta-ext .bg table th {
  font-size: 20px;
  color: #ff4700;
  font-family: "sofiapro bold", sans-serif;
}

.venta-ext .bg table td {
  font-size: 20px;
  color: #000000;
  font-family: "sofiapro", sans-serif;
}

.venta-ext .bg .BoletosPay {
  margin-bottom: 30px;
}

.venta-ext .bg .btn-stack {
  background-color: #fc5705;
  color: #fff;
  border: 1px solid #fc5705;
}

.venta-ext .bg .btn-stack.continue {
  background-color: #65479f;
  color: #fff;
  border: 1px solid #65479f;
}

.venta-ext .bgerror {
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  padding: 80px 40px;
  text-align: center;
}

.venta-ext .bgerror h2 {
  color: #ff4700;
  font-family: "sofiapro bold", sans-serif;
  font-size: 32px;
  text-transform: uppercase;
}

.venta-ext .bgerror label {
  font-size: 20px;
  color: #000000;
  font-family: "sofiapro", sans-serif;
}

.section-login {
  background-image: url("/assets/img/sorteo2023/banner-login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 250px;
}

@media only screen and (max-width: 767px) {
  .section-login {
    padding-top: 100px;
  }
}

.section-login .card-container-radio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  background-color: transparent;
  border: none;
}

@media only screen and (max-width: 1200px) {
  .section-login .card-container-radio {
    padding: 40px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .section-login .card-container-radio {
    flex-direction: column;
  }
}

.section-login .card-container-radio label {
  cursor: pointer;
  color: #fc5705;
  font-family: "sofiapro bold", sans-serif;
}

@media only screen and (max-width: 1200px) {
  .section-login .card-container-radio label {
    color: #333;
    text-align: center;
  }
  .section-login .card-container-radio label img {
    display: block;
  }
}

.section-login .card-container-radio label:hover img {
  background-color: #8c4ef3;
}

.section-login .card-container-radio label img {
  border-radius: 100%;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
  padding: 30px;
  height: 130px;
  margin-right: 15px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .section-login .card-container-radio label img {
    padding: 18px;
    margin: auto;
  }
}

.section-login .card-container-radio label input {
  accent-color: #fc5705;
  opacity: 0;
}

.section-login .card-container-radio label input:checked ~ img {
  background-color: #8c4ef3;
}

.section-login .card-form {
  border: 1px solid #656565;
  padding: 20px 40px;
  margin-bottom: 40px;
}

.section-login .card-form label {
  font-family: "sofiapro bold", sans-serif;
  color: #fc5705;
}

.section-login .card-form .btn-stack {
  border: none;
  margin-top: 15px;
}

.pay-msg {
  background-image: url("/assets/img/sorteo2023/banner-forma-pago.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.pay-msg .row {
  height: 100vh;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
  padding: 80px;
}

.pay-msg .row h2 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 60px;
}

@media only screen and (max-width: 767px) {
  .pay-msg .row {
    padding: 60px 15px;
  }
  .pay-msg .row h2 {
    font-family: "sofiapro bold", sans-serif;
    font-size: 42px;
    color: #8560d2;
    line-height: 1;
  }
}

.stc-comp {
  background-image: url("/assets/img/sorteo2023/banner-forma-pago.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 150px 0;
}

.stc-comp .card {
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
  padding: 80px;
}

.stc-comp .card h3 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 28px;
  color: #333;
}

.stc-comp .card .mensaje {
  opacity: 1;
  transition: opacity 1s;
  text-align: center;
}

.stc-comp .card .mensaje.hidden {
  opacity: 0;
}

.stc-comp .card .btn-stack {
  margin-top: 15px;
  background-color: #ff5900;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .stc-comp .card {
    padding: 60px 15px;
  }
  .stc-comp .card img {
    width: 30px !important;
    height: 40px !important;
    object-fit: contain;
  }
}

.section-colabora {
  background-image: url("/assets/img/sorteo2023/banner-forma-pago.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 180px 0;
}

@media only screen and (max-width: 575px) {
  .section-colabora {
    padding: 140px 0 80px 0;
  }
}

.section-colabora .row {
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
  padding: 80px;
  background-color: #fff;
  border-radius: 30px;
}

@media only screen and (max-width: 575px) {
  .section-colabora .row {
    padding: 80px 15px;
  }
}

.section-colabora .row h1 {
  color: #333;
  font-size: 32px;
  font-family: "sofiapro bold", sans-serif;
}

.section-colabora .row h2 {
  font-family: "sofiapro bold", sans-serif;
  font-size: 24px;
  color: #333;
}

.section-colabora .row label {
  color: #333;
}

.section-colabora .row label input {
  margin-right: 15px;
  accent-color: #fc5705;
  transform: scale(1.2);
}

.section-colabora .row label span {
  color: #333;
  font-size: 20px;
  font-family: "sofiapro bold", sans-serif;
  width: 100px;
  display: inline-block;
}

.section-colabora .row .btn-stack {
  border: none;
  background-color: #fc5705;
  color: #fff;
}

#header-anahuac-onl {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0) 10%, black 30%);
  padding: 0px 0 40px;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: -10px;
}

#header-anahuac-onl img {
  width: 280px;
  margin: 40px 0;
}

.section-login .card #mensajeError {
  display: none;
}

.section-login .card input:invalid {
  border-color: red;
}

.section-login .card input:invalid + #mensajeError {
  display: block;
}

.section-login .card input:valid {
  border-color: green;
}

#btn-gm {
  background-color: #e8531e;
}

#btn-gm:hover {
  background-color: #8661d2;
}
