.stc-venta-exitosa {
    background-image: url('/assets/img/sorteo2024/banner-venta-exitosa.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    &.err-p {
        .error-pago {
            width: 400px;
            height: 300px;
            object-fit: contain;
            text-align: left;
            margin-top: 50px;
            @media only screen and (max-width: 575px) {
                width: 290px;
            }
        }
    }
    .row {
        height: 500px;

        @media only screen and (max-width: 767px) {
            height: 650px;

            img {
                width: 350px;
                margin: auto;
            }
        }
        h1 {
            font-family: $font-bold;
            font-size: 70px;
            color: #fff;
            text-transform: uppercase;
            line-height: 1;

            span {
                font-family: $font-bold;
            }

            @media only screen and (max-width:991px) {
                font-size: 55px;
            }
    
            @media only screen and (max-width: 767px) {
                font-size: 42px;
                margin-top: 100px;
            }
        }

       
    }

    .cta-bottom {
        position: absolute;
        bottom: 60px;
        left: 50%;
        
        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
            animation: pulseArrow 2s infinite;
            cursor: pointer;
        }
    }

}

.venta-ext {
    background-color: #eaeaea;
    .bg {
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
        border-radius: 10px;
        padding: 80px;
        text-align: center;

        @media only screen and (max-width:575px) {
            padding: 80px 20px;
        }
        input[type="radio"], input[type="checkbox"] {
            accent-color:#fc5705;
            transform: scale(1.2);
            margin-right: 10px;
            border-radius: 10px;
        }
        label {
            font-size: 20px;
            color: #000000;
            font-family: $font-stack;   
            &.title {   
                font-family: $font-bold;
            }

            &.small {
                font-size: 16px;
            }
        }

        h2 {
            color: #ff4700;
            font-family: $font-bold;   
            font-size: 20px; 
        }

        table {
            width: 100%;
            font-family: $font-stack;   
            
            th {
                font-size: 20px;
                color: #ff4700;
                font-family: $font-bold;  
            }

            td {
                font-size: 20px;
                color: #000000;
                font-family: $font-stack;   
            }
        }

        .BoletosPay   {
            margin-bottom: 30px;
        }

        .btn-stack {
            background-color: #fc5705;
            color: #fff;
            border: 1px solid #fc5705;
            &.continue {
                background-color: #65479f;
                color: #fff;
                border: 1px solid #65479f;
            }
        }
        
    }


    .bgerror {
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
        border-radius: 10px;
        padding: 80px 40px;
        text-align: center;
        h2 {
            color: #ff4700;
            font-family: $font-bold;   
            font-size: 32px; 
            text-transform: uppercase;
        }

        label {
            font-size: 20px;
            color: #000000;
            font-family: $font-stack; 
        }
    }
}