.section-login {
    background-image: url('/assets/img/sorteo2023/banner-login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 250px;
    @media only screen and (max-width: 767px) {
        padding-top: 100px;
    }

    .card-container-radio {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 40px;
        background-color: transparent;
        border: none;

        @media only screen and (max-width: 1200px) {
            padding: 40px 15px;
        }

        @media only screen and (max-width: 575px) {
            flex-direction: column;
        }

        label {
            cursor: pointer;
            color:#fc5705;
            font-family: $font-bold;
            @media only screen and (max-width: 1200px) {
                color:#333;
                text-align: center;
                img {
                    display: block;
                }
            }


            &:hover {
                img {
                    background-color: #8c4ef3;
                }
            }

            img {
                border-radius: 100%;
                box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
                padding: 30px;
                height: 130px;
                margin-right: 15px;
                transition: all 0.3s ease-in-out;
                @media only screen and (max-width: 767px) {
                    padding: 18px;
                    margin: auto;
                }
            }

            input {
                accent-color:#fc5705;
                opacity: 0;
                &:checked ~ img{
                    background-color: #8c4ef3;
                }
            }
        }
    }

    .card-form {
        border: 1px solid #656565;
        padding: 20px 40px;
        margin-bottom: 40px;
        label {
            font-family: $font-bold;
            color: #fc5705;
        }

        .btn-stack {
            border: none;
            margin-top: 15px;
        }
    }
}