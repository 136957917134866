.banner-home {
    background-image: url('/assets/img/sorteo2024/banner-agrada.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 100px;
   

    .row {
        height: 600px;
        //margin-top: 85px;
        @media only screen and (max-width:991px) {
            height: 850px;
            padding: 60px 0;
        }

        @media only screen and (max-width:767px) {
            height: 700px;
            padding: 30px 0;
        }

        //zod
        .zod {
            h1 {
                font-size: 40px !important;
                span {
                    font-size: 40px !important;
                    
                    @media only screen and (max-width:991px) {
                        font-size: 32px !important;
                    }
                }

                @media only screen and (max-width:991px) {
                    font-size: 32px !important;
                }

             
            }

            h2 {
                font-family: $font-bold;
                font-size: 120px;
                margin-top: -25px;
                text-transform: uppercase;
                letter-spacing: -4px;
                span {
                    font-family: $font-bold;
                    font-size: 42px;
                    @media only screen and (max-width:991px) {
                        font-size: 32px;
                    }
    
                 
                }
                @media only screen and (max-width:991px) {
                    margin-top: -10px;
                    font-size: 60px;
                    letter-spacing: 0px;
                }

           
            }

            .btn-stack {
                background-color: #8661d2;
                font-size: 22px !important;
                border: 1px solid #8661d2;
                &:hover {
                    background-color: #ff5900;
                    color: #fff;
                    border: 1px solid #fff;
                }

                @media only screen and (max-width:991px) {
                    font-size: 16px !important;
                }
            }
        }
        //zod
        .content-text {
            margin-left: -80px;
            @media only screen and (max-width:991px) {
                margin-left: 0px;
            }
            h1 {
               
                .head-h1 {
                    font-family: $font-black;
                    font-size: 46px;
                    margin-top: 30px;
                    text-transform: uppercase;
                    background-color: #ff9d00;
                    width: fit-content;
                }
                span {
                    background-color: #662380;
                    color: #fff;
                    font-size: 38px;
                    //width: fit-content;
                    font-family: $font-black;
                    margin: 0 auto ;
                    padding: 0 10px;
                    box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                    -webkit-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                    -moz-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                }
    
    
                @media only screen and (max-width:575px) {
                    .head-h1 {
                        font-size: 28px;
                    }
                   
                    span {
                        font-size: 22px;
                    }
                }
        
            }
        }

        p {
            font-family: $font-stack;
            font-size: 20px;
            color: #fff;

            span {
                font-family: $font-black;
            }
        }
        
        @media only screen and (max-width: 737px) {
           img {
            margin-top: -50px;
           }
        }
        // .counter-slide {
        //     img {
        //         margin-top: 100px;
              
        //         object-fit: cover;
        //         @media only screen and (max-width: 500px) {
        //             width: auto;
        //             height: auto;
        //             object-fit: contain;
        //             margin-top: 90px;
        //             margin-bottom: 25px;
        //         }
        //     }
        //     .counters {
        //         margin-top: -75px;
        //         .counter {
        //             margin-top: 70px;
        //             position: relative;
        //             .number {
        //                 width: 120px ;
        //                 height: 120px;
        //                 background-color: #59309d;
        //                 color: #fff;
        //                 border-radius: 30px;
        //                 text-align: center;
        //                 span {
        //                     font-family: $font-bold;
        //                     font-size: 90px;
        //                     line-height: 1.2;
                           
        //                 }
        //                 @media only screen and (max-width: 991px) {
        //                     width: 110px ;
        //                     height: 70px;
        //                     span {
        //                         font-size: 48px;
        //                     }
        //                 }

        //                 @media only screen and (max-width: 600px) {
        //                     max-width: 100px;
        //                     width: 100px;
        //                     height: 50px;
        //                     span {
        //                         font-size: 32px;
        //                     }
        //                 }

        //                 @media only screen and (max-width: 480px) {
        //                     width: 50px;
        //                 }

                        
        //             }
        //             @media only screen and (max-width: 480px) {
        //                 width: 50px;
        //             }
        //         }
        //     }

        //     .space {
        //         @media only screen and (max-width: 600px) {
        //             margin-top: 55px !important;
        //         }
        //         span {
        //             font-family: $font-bold;
        //             font-size: 100px;
        //             @media only screen and (max-width: 600px) {
        //                 font-size: 42px;
        //             }
        //         }
        //     }

        //     p {
        //         font-family: $font-black;
        //         font-size: 42px;
        //         line-height: 1;
        //         color: #fff;
        //         @media only screen and (max-width: 600px) {
        //             font-size: 32px;
        //         }
        //         &.subtitle {
        //             font-size: 42px;
        //             font-family: $font-stack;
        //             span {
        //                 font-family: $font-bold;
        //                 font-size: 70px;
        //             }
        //             @media only screen and (max-width: 991px) {
        //                 font-size: 36px;
        //                 span {
        //                     font-size: 52px;
        //                 }
        //             }

        //             @media only screen and (max-width: 480px) {
        //                 font-size: 32px;
        //                 margin-top: 15px;
        //                 span {
        //                     font-size: 46px;
        //                 }
        //             }
        //         }
        //         &.mb-0 {
        //             font-size: 22px;
        //             font-family: $font-stack;
        //             @media only screen and (max-width: 991px) {
        //                 font-size: 18px;
        //             }

        //             @media only screen and (max-width:480px) {
        //                 font-size: 10px;
        //                 margin-top: 5px;
        //             }
        //         }
               
        //     }
        // }

    }
}


#lucky {
    background-image: url(/assets/img/sorteo2024/bg-machine.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 100px 0;
    h3 {
        font-family: $font-bold;
        font-size: 45px;
        text-transform: uppercase;
        line-height: 1;
        span {
            font-family: $font-bold;
        }

        @media only screen and (max-width: 575px) {
            font-size: 32px;
        }
    }

    p {
        font-family: $font-black;
        font-size: 18px;
        color: $color-secondary;
        text-transform: uppercase;
        margin-top: 25px;
        @media only screen and (max-width: 575px) {
            font-size: 16px;
        }
    }

    .content-machine {
        -webkit-box-shadow: 0px 4px 129px 22px rgba(252,228,80,1);
        -moz-box-shadow: 0px 4px 129px 22px rgba(252,228,80,1);
        box-shadow: 0px 4px 129px 22px rgba(252,228,80,1);
        border: 4px solid #fdfcf1;
        border-radius: 30px;
        padding: 25px 35px ;
        display: flex;
        justify-content: center;
        @media only screen and (max-width: 500px) {
            padding: 25px 10px ;
        }
        .numbers {
            list-style: none;
            flex-direction: column;
            width: 55px;
            height: 55px;
            border-radius: 100%;
            position: relative;
            overflow: hidden;
            margin-right: 15px;
            
            @media only screen and (max-width:380px) {
                margin-right: 6px;
            }

            @media only screen and (max-width:330px) {
                margin-right: 2px;
            }

            li {
                color: $color-secondary;
                font-family: "sofiapro";
                font-weight: 700;
                font-size: 42px;
                line-height: 1;
                margin-left: 12px;
                height: 40px;
                @media only screen and (max-width: 1200px) {
                    margin-left: 8px;
                }

                @media only screen and (max-width: 991px) {
                    margin-left: 11px;
                }

                @media only screen and (max-width: 460px) {
                    margin-left: 10px;
                }

                @media only screen and (max-width: 435px) {
                    margin-left: 7px;
                }
            }
        }
        
    }

    .buttons-generate {
        a {
            display: block;
            width: fit-content;
            font-size: 16px;
            margin-top: 25px;
           // min-width: 360px;
            
            @media only screen and (max-width: 390px) {
                min-width: 290px;
            }
            &.generate-number {
                background-color: #e8531e;
                overflow: visible;
                color: #fff;
                padding: 20px 40px;
                line-height: 1;
                animation: pulse 2s infinite;

                // img {
                //     margin-left: -90px;
                //     border-radius: 50%;
                //     background: #8560d0;
                //     cursor: pointer;
                //     box-shadow: 0 0 0 rgba(204,169,44, 0.4);
                //     animation: pulse 2s infinite;
                   
                // }

                // @media only screen and (max-width: 575px) {
                //     padding: 0 20px;
                //     img {
                //         margin-left: -30px;
                //     }
                // }

                @media only screen and (max-width: 359px) {
                    font-size: 14px;
                }

                
    

            }   
        }
    }
   
    

}
.animation-list0 {
    animation: girar0 6s ;
    animation-fill-mode: forwards;
    //animation-iteration-count: infinite;
}

.animation-list1 {
    animation: girar1 6s ;
    animation-fill-mode: forwards;
    //animation-iteration-count: infinite;
}

.animation-list2 {
    animation: girar2 6s ;
    animation-fill-mode: forwards;
    //animation-iteration-count: infinite;
}

.animation-list3 {
    animation: girar3 6s ;
    animation-fill-mode: forwards;
    //animation-iteration-count: infinite;
}

.animation-list4 {
    animation: girar4 6s ;
    animation-fill-mode: forwards;
    //animation-iteration-count: infinite;
}

.animation-list5 {
    animation: girar5 6s ;
    animation-fill-mode: forwards;
    //animation-iteration-count: infinite;
}


@keyframes girar0 {
    100% {
        transform:  translateY( var(--my-start-px0));
    }
}

@keyframes girar1 {
    100% {
        transform:  translateY( var(--my-start-px1));
    }
}


@keyframes girar2 {
    100% {
        transform:  translateY( var(--my-start-px2));
    }
}

@keyframes girar3 {
    100% {
        transform:  translateY( var(--my-start-px3));
    }
}

@keyframes girar4 {
    100% {
        transform:  translateY( var(--my-start-px4));
    }
}

@keyframes girar5 {
    100% {
        transform:  translateY( var(--my-start-px5));
    }
}

.btn-gradient {
    -webkit-box-shadow: 0px 4px 129px 22px rgba(252,228,80,1);
    -moz-box-shadow: 0px 4px 129px 22px rgba(252,228,80,1);
    box-shadow: 0px 4px 129px 22px rgba(252,228,80,1);
    border: 4px solid #fdfcf1;
    border-radius: 100%;
    height: 55px;
    width: 55px;
}
  

#stc-inputs {
    background-color: #eaeaea;
    h3 {
        font-family: $font-bold;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 40px;
        color: #000;
        span {
            font-family: $font-bold;
        }

        @media only screen and (max-width: 575px) {
            font-size: 32px;
        }
    }
    .container-input {
        //box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
       padding: 80px 0;
       
        .inside {
           // background: linear-gradient(#fff, #fff) padding-box,
              //  linear-gradient(to right, #fc5705, #911acf) border-box;
            //border-radius: 0;
            //border: 1px solid transparent;
            padding: 40px 25px;
            label {
                position: relative;
                width: 100%;   
                font-size: 21px;
                color: #333333;
                line-height: 1;
                input[type="radio"], input[type="checkbox"] {
                    accent-color:#6c439b;
                    position: absolute;
                    left: -25px;
                    top: 8px;
                    width: auto;
                    -ms-transform: scale(1.3); /* IE 9 */
                    -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
                    transform: scale(1.3);
                
                }

                @media only screen and (max-width: 575px) {
                   font-size: 18px;
                }
            }
    
            input {
                border: 1px solid #ff5900;
                width: 100%;
                border-radius: 100px;
                text-align: center;
            }
            &::placeholder {
                color: #ff5900;
                opacity: 1; /* Firefox */
              }
              
            &::-ms-input-placeholder { /* Edge 12-18 */
            color: #ff5900;
            }

        }

        .btn-stack {
            border: 0;
            margin-top: 25px;
            font-size: 16px;
        }

        
        
    }

}

.ocultar {
    display: none;
}


#modalBoletos, #modalBoletos2 {
    .row {
        h4, h5 {
            font-size: 30px;
            font-family: $font-bold;
            @media only screen and (max-width: 575px) {
               font-size: 18px;
            }
        }
        input[type="radio"], input[type="checkbox"] {
            accent-color:#fc5705;
            position: absolute;
            left: 25px;
            top: 5px;
            width: auto;
            @media only screen and (max-width: 400px) {
                left: 10px;
            }
        }
     
    }

    .modal-footer {
        .btn-stack {
            border: none ;
            font-size: 16px ;
        }
        @media only screen and (max-width: 400px) {
            justify-content: center;
        }
    }
    
}

#modalBoletos2 {
    h5 {
        font-family: $font-bold;
        font-size: 22px;
    }
    .btn-stack {
        border: 1px solid #ff5900 !important;
        // &:hover {
        //     border: 1px solid #ff5900;
        // }
    }
}


#popupamigable {
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: all .5s;
    z-index: 8;
    overflow-y: scroll;
    .container {
        padding: 40px;
        position: absolute;
        top: 20%;
        background-color: #fff;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 1.5em;
        text-align: center;
     
        label {
            color: #333;
        }
        input[type="radio"], input[type="checkbox"] {
            accent-color:#fc5705;
        }

        input {
            border-radius: 100px;
        }
    }

}

.shwModalCompania {
    display: block;
    background: rgba(0, 0, 0, 0.9);
    overflow: hidden;
}