.stc-carrito {
    background-image: url('/assets/img/sorteo2024/banner-carrito.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .row {
        height: 750px;
    
        h1 {
            margin-left: -130px;
            font-family: $font-bold;
            font-size: 70px;
            color: #fff;
            text-transform: uppercase;
            line-height: 1;
            @media only screen and (max-width:991px) {
                margin-left: 0px;
                text-align: center;
            }
            span {
                font-family: $font-bold;
                background-color: #662380;
                color: #fff;
                width: fit-content;
                font-family: $font-black;
                padding: 0 10px;
                box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                -webkit-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }

            @media only screen and (max-width:991px) {
                margin-top: 130px;
                font-size: 55px;
            }
    
            @media only screen and (max-width:575px) {
                font-size: 38px;
                
            }
        }
    }
}

.stc-boleto {
    background-color: #eaeaea;
    //padding: 40px 0;
    .container-info {
        padding: 70px 40px 80px;
        // box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
        // -webkit-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);
        // -moz-box-shadow: -2px -2px 29px 0px rgba(0,0,0,0.75);

       
        @media only screen and (max-width:575px) {
            
            padding: 104px 0px 80px;
        }
        p {
            width: 100%;
            border: 2px solid transparent;
            font-size: 20px;
            color: #fff;
            padding: 15px 40px;
            background-color: #662380 !important;
            span {
                color: #fff;
                font-family: $font-black;
            }
        }

        label {
            font-size: 25px;
            &.txt {
                color: #ff5900;
                font-family: $font-bold;
            }
            @media only screen and (max-width:575px) {
                font-size: 18px;
            }
        }

        .content-minute {
           
            span {
                font-size: 25px;
            }
            @media only screen and (max-width:575px) {
                margin-bottom: 30px;
               span {
                font-size: 18px;
               }
            }
        }

        i {
            font-size: 25px;
            color: #ff5900;
            @media only screen and (max-width:575px) {
                font-size: 16px; 
            }           
        }

       

        .btn-stack {
            box-shadow: none;
            margin-top: 15px;
            font-size: 20px;
            font-family: $font-stack;
            border: none;
            color: #fff;
            background-color: #ff5900;

            &:hover {
                color: #ff5900;
                background-color: #fff;
                border: 1px solid #ff5900;
            }

            &.purple {
                background-color: #662380;
                color: #fff;
                border: 1px solid #662380;
                &:hover {
                    color: #662380;
                    background-color: #fff;
                    border: 1px solid #662380;
                }
            }

            @media only screen and (max-width:575px) {
                font-size: 16px; 
            }  
        }
    }
}