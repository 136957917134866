/* GENERAL STYLES */

* {
    margin: 0;
    padding: 0;
    font-family: $font-stack;
}

:root {
    --my-start-px0: -760px;
    --my-start-px1: -760px;
    --my-start-px2: -760px;
    --my-start-px3: -760px;
    --my-start-px4: -760px;
    --my-start-px5: -760px;
}



.hamburger {
    padding: 0 !important;
    margin: 0 !important;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #272726;
}
.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    background-color: #272726;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px  !important;
    height: 1px  !important;
}
/**/
$margin-top : 0px;

body {
    position: relative;
    overflow-x: hidden;
}


.overlay { 
    top: -100vh;
    &.active-overlay {
        position: fixed;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        cursor: pointer;
        width: calc(100% - 400px);
        top: 0;
        transition: top 0.5s ease-in-out;
        z-index: 10;

        
        @media only screen and (max-width: 575px) {
            width: calc(100% - 300px);
        }
    }


    
}
.slick-slide {
    height: auto;
}

.btn-stack {
    // -webkit-box-shadow: 2px 3px 70px -14px rgba(114,75,164,0.6);
    // -moz-box-shadow: 2px 3px 70px -14px rgba(114,75,164,0.6);
    // box-shadow: 2px 3px 70px -14px rgba(114,75,164,0.6);
    display: inline-block;
    padding: 10px 30px; 
    background-color: #ff5900;
    color: #fff;
    font-size: 18px;
    font-family: $font-bold;
    border-radius: 100px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    &:hover:not(.generate-number) {
        color: #ff5900;
        background-color: #fff;
        //border: 1px solid #ff5900 !important;
    }
}
.btn-search {
    background-color: #6f5dff;
    &:hover:not(.generate-number) {
        color: #6f5dff;
        background-color: #fff;
        border: 1px solid #6f5dff;
    }
}

/*******loader******/
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    &:after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 1px 19px 13px;
        box-sizing: border-box;
        border: 22px solid $color-primary;
        border-color: $color-primary transparent $color-primary  transparent;
        animation: lds-hourglass 1.2s infinite;
      }
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }


/***********/
/* BORDER COLOR**/
.border-gradient-all {
    background: linear-gradient(#fff, #fff) padding-box,
                linear-gradient(to right, #fc5705, #911acf) border-box;
    border-radius: 100px;
    border: 4px solid transparent;
        
}

.font-bold {
    font-family: $font-bold;
}


a:focus,
button:focus,
a,
button {
    outline: none !important;
    text-decoration: none !important;
}


// FILTERS
.filters {
    ul {
        list-style: none;
        padding: 0;
        li {
            display: inline-block;
            font-size: 18px;
            font-weight: 400;
            color: black;;
            cursor: pointer;
            +li {
                margin-left: 20px;
            }
            &:hover,
            &.active {
                color: #000;
                border-bottom: 1px solid black;;
            }
        }
    }
}

/********LATIDO EGRESADO********/
.latido-egr {
    animation: latido 5s infinite;
}
@keyframes latido {
    from { transform: none; }
    50% { transform: scale(1.09); }
    to { transform: none; }
}




//headers
h1,
h2,
h5,
p,
a,
label,
select,
ul,
li,
ol,
th,
tr,
th {
    font-family: $font-stack;
}

h1,h2,h3 {
    color: $color-secondary;
}



/**SLIDE*/
@media only screen and (max-width: 767px) {
    .slick-dots {
        bottom: 25px !important;
        li {
            button {
                &:before {
                    color: $color-secondary !important;
                    font-size: 10PX;
                }
            }
        }
    }
}


/*MODALS*/



#modalenrollment{
    .modal-content {
        padding: 20px 25px;
        border-radius: 30px;
        
        border: 10px solid transparent;
        label {
            font-size: 20px;
            color:#818181;
            text-transform: uppercase;
            font-family: $font-bold;
            &.text {
                font-size: 14px;
                color: #000000;
                font-family: $font-stack;
                text-transform: none;
                padding: 10px 25px;
            }
        }
     
        .modal-footer {
            border: none;
            margin: 0 auto;
            
        }
        

        input, select {
            border: 1px solid #7d7d7d;
            border-radius: 15px;
        }

        .form-control:focus {
            border-color: #fc5705;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(252, 87, 5, 0.6);
        }

        .btn-modal {
            border: 1px solid #fc5705;
            border-radius: 10px;
            padding: 5px 20px;
            display: inline-block;
            color: #000000;
            font-family: $font-stack;
            font-size: 18px;
            background-color: $color-secondary;
            width: 120px;
            transition: background-color 0.3s ease-in-out;
            cursor: pointer;
            &:hover {
                background-color: #fc5705;
                color: $color-secondary;
               
            }
        }
        
    }

    .alert-danger {
        color: #fff;
        background-color: #eb4d10;
        border-color: #eb4d10;
        border-radius: 100px;
    }

    @media only screen and (max-width: 400px) {
        .modal-content {
            padding: 25px 0  !important;
            p {
                font-size: 16px;
            }
        }
    }
}




/*pulse maquina*/
@-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
      box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 20px rgba(204,169,44, 0);
        box-shadow: 0 0 0 20px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }


  /*PULSE ARROW*/
@-webkit-keyframes pulseArrow {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0); 
        }

    50% {
        -webkit-transform: translate(0, 10px);
                transform: translate(0, 10px); 
        }

    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0); 
        }
            
}
  
  @keyframes pulseArrow {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); 
        }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); 
        }
        
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
        }

}

//spinner

.spinnback {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    border: whitesmoke;
    border-style: solid;
    color: whitesmoke;

    .spinn {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 100;

        label {
            font-size: 32px;
            font-family: $font-bold;
        }
    }
}


.opc-0 {
    opacity: 0;
}

 /*PULSE ARROW*/
 @-webkit-keyframes pulseArrow {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0); 
        }

    50% {
        -webkit-transform: translate(0, 10px);
                transform: translate(0, 10px); 
        }

    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0); 
        }
            
}
  
@keyframes pulseArrow {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); 
        }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); 
        }
        
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
        }

}