/**********BTN WHATS*********/
.btn-call {
    position: fixed;
    right: -130px; 
    top: 45%;
    transition: right 0.3s ease-in-out;
    z-index: 9;
    &:hover {
        right: -15px;
    }     

    .row  {
        background-color: $color-secondary;
        border: 1px solid #959595;
        border-radius: 100px;
        padding: 0;

        img {
            padding-right: 10px;
            width: 115px;
        }
        span {
            font-family: $font-bold;
            font-size: 12px;
            color: #333333;
            line-height: 1;
            text-transform: uppercase;
        }
    }
   
   
}



#main-footer {
    padding: 40px 90px 20px;
    @media only screen and (max-width:575px) {
        padding: 40px 0px 20px;
    }
    .logo-footer {
        margin-bottom: 30px;
    }

    a {
        margin: 10px 0;
        font-size: 16px;
        font-family: $font-stack;
        color: #333333;
        transition: color 0.3s ease-in-out;
        &:hover {
            color: $color-primary;
        }
    }

    h4 {
        font-size: 22px;
        font-family: $font-bold;
        color: #333;
        text-transform: uppercase;
    }

    h5 {
        font-size: 28px;
        font-family: $font-bold;
        color: #333;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 991px) {
        ul {
            margin: 0 25px;
        }
    }

    .redes {
        img {
            width: 32px;
            object-fit: cover;
            margin-right: 10px;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }

            @media only screen and (max-width: 1200px) {
                width: 25px;
            }
        }

        @media only screen and (max-width: 991px) {
            margin-top: 25px !important;

            img {
                width: 35px;
            }
        }
    }

    .sto {
        margin: 30px 0;
        .main-sorteo {
            border-radius: 10px;
            padding: 25px 30px;
            margin: 15px 50px;
            -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
            p {
                font-size: 14px;
                font-family: $font-bold;
                text-transform: uppercase;
                color: #fff;
            }

            @media only screen and (max-width: 1200px) {
               margin: 15px 0;
            }
        }
    }

    .legals {
        p {
            font-family: $font-stack;
            font-size: 16px;
            color: #333333;
        }
        @media only screen and (max-width: 991px) {
            margin: 25px 0 !important;
        }
    }

   
}

.derechos {
    padding: 15px;
    background-color: #e8e8e8;

    p {
        font-family: $font-stack;
        font-size: 14px;
        color: #333333;
    }
}
.campus {
    background-color: #8560d2;
    padding: 50px 0 80px;
    max-height: 290px;
    @media only screen and (max-width: 1300px) {
        max-height: inherit;
    }
    h5 {
        text-transform: uppercase;
        font-size: 42px;
        color: #fff;
        font-family: $font-black;
        margin-bottom: 40px;
        @media only screen and (max-width: 575px) {
            font-size: 32px;
        }
    }

    img {
        margin-bottom: 30px;
    }
}