
.stc-comp {
    background-image: url('/assets/img/sorteo2023/banner-forma-pago.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 150px 0;
    .card {
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
        padding: 80px;
        
        h3 {
            font-family: $font-bold;
            font-size: 28px;
            color: #333;
        }

        .mensaje {
            opacity: 1;
            transition: opacity 1s;
            text-align: center;
        }
        
        .mensaje.hidden{
            opacity: 0;
        }

        .btn-stack {
            margin-top: 15px;
            background-color: #ff5900;
            color: #fff;
        }

        @media only screen and (max-width: 767px) {
            padding: 60px 15px;
            
            img {
                width: 30px !important;
                height: 40px !important;
                object-fit: contain;
            }
        }
    }

}


