
.section-colabora {

    background-image: url('/assets/img/sorteo2023/banner-forma-pago.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 180px 0;
    @media only screen and (max-width:575px) {
        padding: 140px 0 80px 0;
    }
    .row {
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
        padding: 80px;
        background-color: #fff;
        border-radius: 30px;

        @media only screen and (max-width:575px) {
            padding: 80px 15px;
            
        }
        h1 {
            color: #333;
            font-size: 32px;
            font-family: $font-bold;
        }


        h2 {
            font-family: $font-bold;
            font-size: 24px;
            color: #333;
        }
        
        
        label {
            color: #333;
            input {
                margin-right: 15px;
                accent-color:#fc5705;
                transform: scale(1.2);
            }

            span {
                color: #333;
                font-size: 20px;
                font-family: $font-bold;
                width: 100px;
                display: inline-block;
            }
        }

        .btn-stack {
            border: none;
            background-color: #fc5705;
            color: #fff;
        }
    }
}

.anahuac-online {
   
}

#header-anahuac-onl {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0) 10%, black 30%);
    padding: 0px 0 40px;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: -10px;

    img {
        width: 280px;
        margin: 40px 0;
    }

}